<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>{{ $t('users.dialogs.addNew.title') }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="addUserForm" v-model="valid" :disabled="loading">
            <v-row>
              <v-col cols="12">
                <label for="name">{{ $t('users.dialogs.addNew.name') }}</label>
                <v-text-field
                  v-model="newUser.name"
                  id="name"
                  outlined
                  dense
                  :rules="rules.userName"
                ></v-text-field>
                <label for="surname">{{ $t('users.dialogs.addNew.surname') }}</label>
                <v-text-field
                  v-model="newUser.surname"
                  id="surname"
                  outlined
                  dense
                  :rules="rules.surname"
                ></v-text-field>
                <label for="phone">{{ $t('users.dialogs.addNew.phone') }}</label>
                <v-text-field
                  v-model="newUser.phone"
                  id="phone"
                  outlined
                  dense
                  :rules="[...required, maxCharInPhoneNumber]"
                ></v-text-field>
                <label for="email">{{ $t('users.dialogs.addNew.email') }}</label>
                <v-text-field
                  v-model="newUser.email"
                  id="email"
                  outlined
                  dense
                  :rules="emailRules"
                ></v-text-field>
                <label class="pt-4">{{ $t('users.dialogs.addNew.role') }}</label>
                <v-select
                  v-model="newUser.customerServiceRole"
                  :items="customerServiceRoleTypes"
                  item-value="id"
                  item-text="description"
                  dense
                  outlined
                  :rules="requiredSelect"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-10">
        <v-spacer></v-spacer>
        <v-btn
          color="secodary"
          outlined
          class="mr-2"
          :disabled="loading"
          @click="close"
        >
          {{ $t('users.dialogs.addNew.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="loading"
          :loading="loading"
          @click="submit"
        >
          {{ $t('users.dialogs.addNew.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { maxCharInPhoneNumber } from '@/utils/helpers'

export default {
  name: 'AddNew',
  props: ['dialog', 'loading'],
  data () {
    return {
      valid: true,
      newUser: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        customerServiceRole: null
      },
      defaultUser: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        customerServiceRole: null
      },
      emailRules: [
        (v) => !!v.trim() || this.$t('validation.emailRequired'),
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.emailInvalidFormat')
      ],
      required: [(v) => !!v.trim() || this.$t('validation.fieldRequired')],
      maxCharInPhoneNumber,
      requiredSelect: [(v) => !!v || this.$t('validation.fieldRequired')],
      rules: {
        userName: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 15 || this.$t('validation.maxCountChar', { count: 15 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ],
        surname: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 25 || this.$t('validation.maxCountChar', { count: 25 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ]
      }
    }
  },
  watch: {
    dialog () {
      this.newUser = Object.assign({}, this.defaultUser)
    }
  },
  computed: {
    customerServiceRoleTypes () {
      return this.$store.getters.enums.customerServiceRoleTypes
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.$refs.addUserForm.resetValidation()
    },
    submit () {
      this.$refs.addUserForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit', this.newUser)
      this.$refs.addUserForm.resetValidation()
    }
  }
}
</script>
