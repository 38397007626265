<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card v-if="user">
      <v-card-title>
        <span class="text-h5"
          >{{ $t("users.dialogs.edit.title") }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="editForm" v-model="valid" :disabled="loading">
            <v-row>
              <v-col>
                <label for="name">{{ $t("users.dialogs.edit.name") }}</label>
                <v-text-field
                  v-model="user.name"
                  id="name"
                  outlined
                  dense
                  :rules="rules.userName"
                ></v-text-field>
                <label for="surname">{{
                  $t("users.dialogs.edit.surname")
                }}</label>
                <v-text-field
                  v-model="user.surname"
                  id="surname"
                  outlined
                  dense
                  :rules="rules.surname"
                ></v-text-field>
                <label for="phone">{{ $t("users.dialogs.edit.phone") }}</label>
                <v-text-field
                  v-model="user.phone"
                  id="phone"
                  outlined
                  dense
                  :rules="[...required, maxCharInPhoneNumber]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <label for="street">{{
                  $t("users.dialogs.edit.street")
                }}</label>
                <v-text-field
                  v-model="user.street"
                  id="street"
                  outlined
                  dense
                ></v-text-field>
                <label for="zipCode">{{
                  $t("users.dialogs.edit.zipCode")
                }}</label>
                <v-text-field
                  v-model="user.zipCode"
                  id="zipCode"
                  outlined
                  dense
                ></v-text-field>
                <label for="city">{{ $t("users.dialogs.edit.city") }}</label>
                <v-text-field
                  v-model="user.city"
                  id="city"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <label class="pt-4"> {{ $t("users.dialogs.edit.role") }}</label>
                <v-select
                  v-model="user.role"
                  :items="customerServiceRoleTypes"
                  item-value="id"
                  item-text="description"
                  dense
                  outlined
                  :rules="requiredSelect"
                ></v-select></v-col></v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secodary"
              outlined
              :disabled="loading"
              @click="close"
            >
              {{ $t("users.dialogs.edit.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="submit"
            >
              {{ $t("users.dialogs.edit.submit") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { maxCharInPhoneNumber } from '@/utils/helpers'

export default {
  name: 'EditProfile',
  props: ['dialog', 'user', 'loading'],
  data () {
    return {
      valid: true,
      required: [(v) => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')],
      requiredSelect: [(v) => !!v || this.$t('validation.fieldRequired')],
      maxCharInPhoneNumber,
      rules: {
        userName: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 15 || this.$t('validation.maxCountChar', { count: 15 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ],
        surname: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 25 || this.$t('validation.maxCountChar', { count: 25 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ]
      }
    }
  },
  computed: {
    customerServiceRoleTypes () {
      return this.$store.getters.enums.customerServiceRoleTypes
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.$refs.editForm.resetValidation()
    },
    submit () {
      this.$refs.editForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit', this.user)
      this.$refs.editForm.resetValidation()
    }
  }
}
</script>
