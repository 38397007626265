<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>{{
        $t("users.dialogs.resetUserPassword.title")
      }}</v-card-title>
      <v-card-text>
        <v-container>
          <p
            class="mb-8"
            v-html="
              $t('users.dialogs.resetUserPassword.description', {
                name: user.name,
                surname: user.surname,
                email: user.email,
              })
            "
          ></p>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secodary"
          outlined
          class="mr-2"
          :disabled="loading"
          @click="$emit('cancel')"
        >
          {{ $t("users.dialogs.resetUserPassword.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="loading"
          :loading="loading"
          @click="$emit('reset')"
        >
          {{ $t("users.dialogs.resetUserPassword.reset") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ResetPassword',
  props: ['dialog', 'user', 'loading']
}
</script>
